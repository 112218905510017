<template>
    <div class="foundation">
        <div class="main-limiter margin-bottom-6">
            <h1 class="margin-bottom-0">AI REV Foundation</h1>
            <p class="text-center text-bold margin-top-0a">We significantly help talented youth in Poland and the Philippines<br class="hide-phone" /> to escape poverty and nourish their interest in Computer Science.</p>
        </div>

        <div class="main-limiter margin-bottom-5">
            <el-row :gutter="20">
				<el-col :xs="24" :span="10" style="float:right">
					<div class="margin-top-0b">
						<DoroImage :src="ImageWorkshops1" shadow></DoroImage>
					</div>
				</el-col>
                <el-col :xs="24" :span="14">
                    <h2 class="margin-top-0">Advanced Machine Learning workshops for talented youth</h2>
                    <p>
                        In January of 2020, we've hosted a Machine Learning Workshops for 70 selected, talented young students from Europe, who are preparing for international IT competitions.
                    </p>
                    <div class="margin-top-1a">
                        <URL to="https://www.facebook.com/groups/809462216168194/"><DoroButton>Find out more</DoroButton></URL>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="main-limiter margin-top-5 margin-bottom-5">
            <el-row :gutter="20">
                <el-col :xs="24" :span="10">
                    <div class="margin-top-0b">
                        <DoroImage :src="ImageWorkshops2" shadow></DoroImage>
                    </div>
                </el-col>
                <el-col :xs="24" :span="14">
                    <h2 class="margin-top-0">Workshops in the Philippines</h2>
                    <p>
						In February of 2020, we've hosted an introductory robotics and algorithms workshops to 250+ impoverished students. They don't have access to education due to military conflicts, homelessness, and other life problems. To execute this plan, we partnered with six other NGOs.
                    </p>
                    <div class="margin-top-1a">
                        <URL to="https://www.linkedin.com/posts/marek-bardonski_we-are-bringing-hope-for-youth-in-the-philippines-activity-6636667114054787072--lYq"><DoroButton>Find out more</DoroButton></URL>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="main-limiter margin-top-5 margin-bottom-5">
            <el-row :gutter="20">
                <el-col :xs="24" :span="8">
                    <DoroImage :src="ImageWorkshopsPhilipines1" shadow></DoroImage>
                </el-col>
                <el-col :xs="24" :span="8">
                    <DoroImage :src="ImageWorkshopsPhilipines2" shadow></DoroImage>
                </el-col>
                <el-col :xs="24" :span="8">
                    <DoroImage :src="ImageWorkshopsPhilipines3" shadow></DoroImage>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
    import DoroImage from '@/components/DoroImage';
    import DoroButton from '@/components/DoroButton';
    import URL from '@/components/URL';

    import ImageWorkshops1 from '@/assets/pages/foundation/warsztaty-1.jpg';
    import ImageWorkshops2 from '@/assets/pages/foundation/warsztaty-2.jpg';
    import ImageWorkshopsPhilipines1 from '@/assets/pages/foundation/warsztaty-filipiny-1.jpg';
    import ImageWorkshopsPhilipines2 from '@/assets/pages/foundation/warsztaty-filipiny-2.jpg';
    import ImageWorkshopsPhilipines3 from '@/assets/pages/foundation/warsztaty-filipiny-3.jpg';

    @Component({
        components: {
            DoroImage,
            DoroButton,
            URL,
        },
        metaInfo() {
            return {
                title: 'AI REV - Foundation',
            }
        }
    })
    export default class Foundation extends Vue {
        get ImageWorkshops1() { return ImageWorkshops1; }
        get ImageWorkshops2() { return ImageWorkshops2; }
        get ImageWorkshopsPhilipines1() { return ImageWorkshopsPhilipines1; }
        get ImageWorkshopsPhilipines2() { return ImageWorkshopsPhilipines2; }
        get ImageWorkshopsPhilipines3() { return ImageWorkshopsPhilipines3; }
    }
</script>